@import '~antd/dist/antd.less';

@primary-color: #312EFA; // primary color for all components
@link-color: #312EFA; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@font-size-sm: 10px;
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #282E44; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers


::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f2f2f2;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -khtml-border-radius: 0px;
    border-radius: 0px;
}

::-webkit-scrollbar {
    background-color: #f2f2f2
}

::-webkit-scrollbar-thumb {
    background: #C9C9C9;
    // -moz-border-radius: 4px;
    // -webkit-border-radius: 4px;
    // -khtml-border-radius: 4px;
    // border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
    cursor: pointer;
}

html,body{
    height: 100%
}

.wrapper{
    position: relative;
    height: 100%;
    overflow-y: auto;
}

.auth-wrapper{
    position: relative;
    height: 100%;        
    display: flex;
    width: 100%;
    background-color: #FFF;
    align-items: center;
    .auth-left-column{
        width: 60%;
        height: 100%;
        align-items: flex-end;
        display: flex;
        background-image: url('./images/auth-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-color: @primary-color;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: fade(@primary-color, 40%);
            z-index: 1;
        }
        .auth-text{
            padding: 3.5em;
            color: #FFF;
            position: relative;
            z-index: 3;
            h2{
                color: #FFF;
            }
        }
    }
    .auth-form{
        width: 40%;
        overflow-y: auto;
        max-height: 100%;
        .auth-form-inner{
            padding: 3em;
            width: 100%;
            max-width: 450px;
            margin: auto;
            .auth-options{
                margin-top: 50px;
                p{
                    margin: 0;
                }
            }
        }
    }
}

.ant-form-item-label{
    padding: 0 !important;
}

.ant-form-item{
    margin-bottom: 15px !important;
}

.notification-error{
    background-color: @error-color;
    color: #FFF;
    .anticon.ant-notification-notice-icon-error{
        color: #FFF;
    }
    .ant-notification-notice-message{
        color: #FFF;
        font-weight: bold;
    }
}

.notification-success{
    background-color: @success-color;
    color: #FFF;
    .anticon.ant-notification-notice-icon-error{
        color: #FFF;
    }
    .ant-notification-notice-message{
        color: #FFF;
        font-weight: bold;
    }
}

.dashboard-wrapper{
    position: relative;
    height: 100%;
    .sidebar{
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        overflow-y: auto;
        width: 260px;
        background-color: #F6FAFC;
        border-right: 1px solid #E2E8EE;
        z-index: 6;
        .sidebar-inner{
            padding: 20px 30px;
            .logo{
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 30px;
            }
            ul{                
                list-style-type: none;
                margin: 25px 0;
                padding: 0;
                li{                    
                    a{
                        margin: 3px 0;
                        display: block;
                        padding: 5px 0;
                        color: @text-color;
                        span{
                            width: 24px;
                        }
                        &:hover{
                            color: darken(@text-color, 20%);
                        }
                    }
                    &.active{
                        a{
                            color: @primary-color;
                        }
                    }
                }
            }
        }
    }
    .dashboard-contents{
        position: relative;
        height: 100%;
        overflow-y: auto;
        background-color: #FFF;
        padding-left: 260px;
        z-index: 3;
        .dashboard-contents-inner{
            padding: 2em;
            padding-top: 70px;
        }
        .header{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;            
            padding-left: 260px;
            z-index: 4;
            background-color: #FFF;
            border-bottom: 1px solid #E2E8EE;
            .header-inner{
                padding: 10px 2em;
                text-align: right;
                .bell-icon{
                    margin-right: 15px;
                }                
            }
        }
    }
    .sidecart{
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;        
        width: 35%;
        background-color: #F6FAFC;
        border-left: 1px solid #E2E8EE;
        z-index: 6;
        .sidecart-header{
            height: 50px;
            padding: 10px 20px;
        }
        .ant-tabs{            
            height: calc(100% - 100px);   
            .ant-tabs-nav{
                margin: 0;
                padding: 0 2em;
            }
            .ant-tabs-content-holder{
                height: calc(100% - 50px);
                overflow-y: auto;
                padding: 20px 2em;
            }
        }        
    }
    .user-dashboard-contents{
        padding-right: 35%;
        height: 100%;
        position: relative;
        overflow:hidden;        
        .user-header{
            z-index: 3;
            border-bottom: 1px solid #E2E8EE;
            padding: 5px 2em;
            position: fixed;
            height: 40px;
            left: 0;
            top: 0;
            width: 100%;
            background-color: #FFF;
            .logo{
                font-weight: 700;
                font-size: 18px;
            }
        }
        .user-contents{
            position: relative;
            padding-top: 40px;
            height: 100%;

        }
        .user-dashboard-contents-inner{
            position: relative;
            height: calc(100% - 42px) !important; 
            overflow-y: auto !important;
            padding: 2em 3em;
        }
    }
}

.header-user-menu{
    .user-meta{
        padding: 10px 20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .user-links{
        padding: 5px 20px;
        a{
            display: block;
            font-size: 12px;
            margin: 5px 0;
        }
    }
    h3{
        font-size: 14px;        
        margin: 0;
    }
    p{
        margin: 0;
        font-size: 12px;
    }
}

.user-menu{
    .ant-popover-inner-content{
        padding: 0;
    }
}

.text-right{
    text-align: right !important;
}


.mt-4{
    margin-top: 35px;
}
.text-center{
    text-align: center !important;
}


.ant-btn{
    span{
        font-size: 13px;
    }
}

.ant-btn-lg{
    span{
        font-size: 15px;
    }
}

.dashboard-table{
    width: 100%;
    margin: 30px 0;
    tr{
        th{
            text-align: left;
            font-weight: normal;
            font-size: 12px;
            font-weight: 600;
            border-bottom: 1px solid #E2E8EE;
            text-transform: uppercase;
            padding: 3px 0px;
        }
        td{
            text-align: left;
            font-weight: normal;
            font-size: 13px;
            font-weight: 300;
            border-bottom: 1px solid #E2E8EE;
            padding: 10px 0px;
            vertical-align: top;
            strong{
                color: @primary-color;
                font-weight: 600;
                cursor: pointer;
            }
            &.tags-col{
                width: 45%;
                .ant-tag{
                    margin-right: 3px;
                    margin-bottom: 3px;
                }
            }
            &.text-right{
                padding-right: 20px;
            }
        }
    }
    tbody{
        tr{
            &:hover{
                td{
                    background-color: rgba(226, 232, 238, 0.3)
                }
            }
        }
    }
}

h2{
    font-weight: 700;
}


.dashboard-box{
    background-color: #f9f9f9;
    padding: 20px;
    margin: 15px 0;
    position: relative;
    border: 1px solid #f2f2f2;
    span{
        position: absolute;
        right: 20px;
        top: 15px;
        font-size: 42px;        
    }
    strong{
        font-size: 32px;
        font-weight: 600;
    }
    h4{
        font-weight: normal;
        margin: 0;
    }
}

.categories-list-wrapper{
    position: relative;
    padding: 0 36px;
    .arrows{
        span.arrow{
            position: absolute;
            width: 32px;
            height: 100%;
            text-align: center;
            cursor: pointer;
            top: 0;
            background-color: @primary-color;
            color: #FFF;
            &.previous{
                left: 0;
            }       
            &.next{
                right: -1px;                
            }  
            span{
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }   
        }
    }
}
.user-categories-list{
    width: 100%;
    overflow-x: hidden;
    border-bottom: 1px solid #E2E8EE;
    scroll-behavior: smooth;
    white-space: nowrap;
    ul{
        list-style-type: none;
        margin: 0;        
        padding: 0;
        li{
            display: inline-block;
            margin: 0 10px;
            color: @primary-color;
            padding: 10px 10px;
            cursor: pointer;
            font-weight: 600;
            &.active{
                border-bottom: 2px solid @primary-color;
            }
        }
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .product-table{
      width: 100%;
      margin-bottom: 25px;
      border-radius: 10px;
      border-collapse:separate;
      border-spacing:0 5px;
    //   border: 1px solid @primary-color;
      tr{
          th{
              padding: 2px 10px;
              font-size: 12px;
              color: @primary-color;
            //   background-color: @primary-color;
              text-align: left;
          }
          td{
            padding: 5px 10px;
            text-align: left;
            // border-bottom: 1px solid #e9e9e9;            
        }
        &:last-child{
            td{
                border-bottom: 0;
            }
        }
        &:hover{
            td{
                background-color: #e3ebee;
            }
        }
      }
      tbody{
          tr{
              border: 2px solid @primary-color;              
              td{
                  background-color: #F6FAFC;
                  &:first-child{
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                  }
                  &:last-child{
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                  }
              }
          }
      }
  }

.cart-item{
    background-color: #FFF;
    padding: 10px 20px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    h4{
        font-weight: 600;
        margin-bottom: 5px;
    }
    p{
        font-size: 12px;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.5);
        strong{
            font-size: 14px;
            color: rgba(0, 0, 0, 0.95);
        }
    }
    
    .remove-link{
        color: @error-color;
    }
}

.custom-item-form{
    border-top: 1px solid #e9e9e9;
    padding-top: 15px;
    margin: 15px 0;
}

.footer-inner{
    border-top: 1px solid #e9e9e9;
    padding: 8px 2em;
}

.item-comments{
    padding: 10px 0;
    padding-bottom: 0;
    margin-top: 15px;
    border-top: 1px solid #e9e9e9;
    p{
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);        
    }    
    .ant-typography{
        margin-bottom: 0 !important;
        p{
            margin-bottom: 0 !important;
        }
    }
}

.quote-details{
    h2{
        margin-bottom: 30px;
    }
    h3{
        margin: 25px 0;
    }
}